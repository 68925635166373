import { RemixBrowser } from '@remix-run/react';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';

// if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
//   const { default: whyDidYouRender } = await import(
//     '@welldone-software/why-did-you-render'
//   );
//   whyDidYouRender(React);
// }

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
